import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import router from "./../router";

Vue.use(AclInstaller);

let initialRole = "anonymous";
let userInfo = JSON.parse(localStorage.getItem("userInfo"));
if (userInfo && userInfo.userRole) initialRole = userInfo.userRole;
export default new AclCreate({
  initial: initialRole,
  notfound: "/pages/login",
  router,
  acceptLocalRules: true,
  globalRules: {
    hospitalgroup: new AclRule("hospitalgroup").generate(),
    admin: new AclRule("admin").generate(),
    editor: new AclRule("editor").or("admin").generate(),
    all: new AclRule("patientserviceagent").or("adminPatientAgent").or("admin").or("accountmanager").or("agentssupervisor").or("accountmanagersupervisor").generate(),
    anonymous: new AclRule("anonymous").or("patientserviceagent").or("adminPatientAgent").or("admin").or("accountmanager").or("agentssupervisor").or("accountmanagersupervisor").or("admin").or("editor"),
    patientserviceagent: new AclRule("patientserviceagent").generate(),
    accountmanager:new AclRule("accountmanager").generate(),
    adminPatientAgent: new AclRule("patientserviceagent").or("agentssupervisor").or("admin").generate(),
    adminaccountmanager: new AclRule("accountmanager").or("accountmanagersupervisor").or("admin").generate(),
    hospitals: new AclRule("accountmanager").or("accountmanagersupervisor").or("hospitalgroup").or("admin").generate(),
    adminagentssupervisor: new AclRule("agentssupervisor").or("admin").generate(),
    adminaccountmanagersupervisor: new AclRule("accountmanagersupervisor").or("admin").generate(),
    home: new AclRule("patientserviceagent").or("adminPatientAgent").or("admin").or("accountmanager").or("hospitalgroup").or("agentssupervisor").or("accountmanagersupervisor").generate(),
    allagentandadmin: new AclRule("admin").or("accountmanager").or("patientserviceagent").generate(),
    booking: new AclRule("admin").or("accountmanager").or("patientserviceagent").or("hospitalgroup").generate(),
  }
});
