/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import auth from "@/auth/authService";
import store from '@/store/store.js'
// import firebase from 'firebase/app'
// import 'firebase/auth'

Vue.use(Router);

const router = new Router({
    //  mode: 'history',

    base: process.env.Base_Url,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [{
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: "",
            component: () =>
                import ("./layouts/main/Main.vue"),
            children: [
                // =============================================================================
                // Pages Routes
                // =============================================================================

                {
                    path: "/",
                    name: "home",
                    component: () =>
                        import ("./views/Home.vue"),
                    meta: {
                        rule: "home",

                        /// authRequired: true
                    }
                },

                // =============================================================================
                // Pages Routes
                // =============================================================================

                

                {
                    path: "/ChangePassword",
                    name: "ChangePassword",
                    component: () =>
                        import ("@/views/changePassword.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },

                            { title: "ChangePassword", active: true }
                        ],
                        pageTitle: "Change Password",
                        rule: "all"
                    }
                },

                {
                    path: "/MedicalPackage",
                    name: "MedicalPackage",
                    component: () =>
                        import ("@/views/medicalPackage/MedicalPackage.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },

                            { title: "Medical Package", active: true }
                        ],
                        pageTitle: "Medical Package",
                        rule: "admin"
                    }
                },
                {
                    path: "/MedicalPackageAddEdit/:Id?",
                    name: "MedicalPackageAddEdit",
                    component: () =>
                        import ("@/views/medicalPackage/MedicalPackage_Edit.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },

                            { title: "Medical Package Add&Edit", active: true }
                        ],
                        pageTitle: "Medical Package Add&Edit",
                        rule: "admin"
                    }
                },
                {
                    path: "/AppointmentMarkup",
                    name: "AppointmentMarkup",
                    component: () =>
                        import ("@/views/AppointmentMarkup/AppointmentMarkup.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },

                            { title: "Appointment Markup", active: true }
                        ],
                        pageTitle: "Appointment Markup ",
                        rule: "admin"
                    }
                },
                {
                    path: "/AppointmentMarkupAddEdit/:Id?",
                    name: "AppointmentMarkupAddEdit",
                    component: () =>
                        import ("@/views/AppointmentMarkup/AppointmentMarkup_Edit.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },

                            { title: "Appointment Markup  Add&Edit", active: true }
                        ],
                        pageTitle: "Appointment Markup Add&Edit",
                        rule: "admin"
                    }
                },
                {
                    path: "/TapPayment",
                    name: "TapPayment",
                    component: () =>
                        import ("@/views/TapPayment/TapPayment.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },

                            { title: "Tap Payment", active: true }
                        ],
                        pageTitle: "Tap Payment",
                        rule: "admin"
                    }
                },
                {
                    path: "/SurgeryMarkup",
                    name: "SurgeryMarkup",
                    component: () =>
                        import ("@/views/SurgeryMarkup/SurgeryMarkup.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },

                            { title: "Surgery Markup", active: true }
                        ],
                        pageTitle: "Surgery Markup  ",
                        rule: "admin"
                    }
                },
                {
                    path: "/SurgeryMarkupAddEdit/:Id?",
                    name: "SurgeryMarkupAddEdit",
                    component: () =>
                        import ("@/views/SurgeryMarkup/SurgeryMarkup_Edit.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },

                            { title: "Surgery Markup Markup  Add&Edit", active: true }
                        ],
                        pageTitle: "Surgery Markup  Add&Edit",
                        rule: "admin"
                    }
                },

                {
                    path: "/Setting/MedicalPackageCategory",
                    name: "MedicalPackageCategory",
                    component: () =>
                        import ("@/views/settings/MedicalPackageCategory/MedicalPackageCategory.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "MedicalPackageCategory", active: true }
                        ],
                        pageTitle: "MedicalPackageCategory",
                        rule: "editor"
                    }
                },
                {
                    path: "/Setting/Country",
                    name: "Country",
                    component: () =>
                        import ("@/views/settings/country/Country.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Country", active: true }
                        ],
                        pageTitle: "Country",
                        rule: "editor"
                    }
                },

                {
                    path: "/HospitalSurgeries/:ID?",
                    name: "HospitalSurgeries",
                    component: () =>
                        import ("@/views/Hospital/HospitalSurgeries.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Hospital Surgeries", active: true }
                        ],
                        pageTitle: "Hospital Surgeries",
                        rule: "hospitals"
                    }
                },

                {
                    path: "/HospitalDoctors/:ID?",
                    name: "HospitalDoctors",
                    component: () =>
                        import ("@/views/Hospital/HospitalDoctors.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Hospital Doctors", active: true }
                        ],
                        pageTitle: "Hospital Doctors",
                        rule: "hospitals"
                    }
                },
                {
                    path: "/HospitalSpecialities/:ID?",
                    name: "HospitalSpecialities",
                    component: () =>
                        import ("@/views/Hospital/HospitalSpecialities.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Hospital Specialities", active: true }
                        ],
                        pageTitle: "Hospital Specialities",
                        rule: "hospitals"
                    }
                },
                {
                    path: "/PatientResevationSurgeries/:ID?",
                    name: "PatientResevationSurgeries",
                    component: () =>
                        import ("@/views/Hospital/PatientResevationSurgeries.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient Resevation Surgeries ", active: true }
                        ],
                        pageTitle: "Patient Resevation Surgeries ",
                        rule: "hospitals"
                    }
                },
                {
                    path: "/ReservationMedicalPackage",
                    name: "ReservationMedicalPackage",
                    component: () =>
                        import ("@/views/reservationMedicalPackage/ReservationMedicalPackage.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Reservation Medical Package", active: true }
                        ],
                        pageTitle: "Reservation Medical Package ",
                        rule: "allagentandadmin"
                    }
                },
                {
                    path: "/ReservationMedicalPackage-Details/:ID?",
                    name: "ReservationMedicalPackage-Details",
                    component: () =>
                        import ("@/views/reservationMedicalPackage/ReservationMedicalPackage-Details.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "ReservationMedicalPackage-Details", active: true }
                        ],
                        pageTitle: "ReservationMedicalPackage-Details",
                        rule: "allagentandadmin"
                    }
                },
                {
                    path: "/Setting/HospitalGroup",
                    name: "HospitalGroup",
                    component: () =>
                        import ("@/views/settings/HospitalGroup/HospitalGroup.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "HospitalGroup", active: true }
                        ],
                        pageTitle: "HospitalGroup",
                        rule: "editor"
                    }
                },
                {
                    path: "/Setting/Region",
                    name: "Region",
                    component: () =>
                        import ("@/views/settings/region/Region.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Region", active: true }
                        ],
                        pageTitle: "Region",
                        rule: "editor"
                    }
                },
                {
                    path: "/Setting/Diagnosis",
                    name: "Diagnosis",
                    component: () =>
                        import ("@/views/settings/Diagnosis/Diagnosis.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Diagnosis", active: true }
                        ],
                        pageTitle: "Diagnosis",
                        rule: "editor"
                    }
                },
                {
                    path: "/Setting/Medicine",
                    name: "Medicine",
                    component: () =>
                        import ("@/views/settings/Medicine/Medicine.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Medicine", active: true }
                        ],
                        pageTitle: "Medicine",
                        rule: "editor"
                    }
                },
                {
                    path: "/Setting/City",
                    name: "City",
                    component: () =>
                        import ("@/views/settings/city/City.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "City", active: true }
                        ],
                        pageTitle: "City",
                        rule: "editor"
                    }
                },
                {
                    path: "/Setting/Currency",
                    name: "Currency",
                    component: () =>
                        import ("@/views/settings/currency/Currency.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Currency", active: true }
                        ],
                        pageTitle: "Currency",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/Language",
                    name: "Language",
                    component: () =>
                        import ("@/views/settings/language/Language.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Language", active: true }
                        ],
                        pageTitle: "Language",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/PriceManagement",
                    name: "PriceManagement",
                    component: () =>
                        import ("@/views/settings/PriceManagement/PriceManagement.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Price Management ", active: true }
                        ],
                        pageTitle: "Price Management ",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/InsuranceCompany",
                    name: "InsuranceCompany",
                    component: () =>
                        import ("@/views/settings/Insurance/InsuranceCompany.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Insurance Company", active: true }
                        ],
                        pageTitle: "Insurance Company",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/PaymentType",
                    name: "PaymentType",
                    component: () =>
                        import ("@/views/settings/paymentType/PaymentType.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "PaymentType", active: true }
                        ],
                        pageTitle: "PaymentType",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/PaymentStatus",
                    name: "PaymentStatus",
                    component: () =>
                        import ("@/views/settings/paymentStatus/PaymentStatus.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "PaymentStatus", active: true }
                        ],
                        pageTitle: "PaymentStatus",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/DoctorRequestType",
                    name: "DoctorRequestType",
                    component: () =>
                        import ("@/views/settings/doctorRequestType/DoctorRequestType.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "DoctorRequestType", active: true }
                        ],
                        pageTitle: "DoctorRequestType",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/SmokeDegree",
                    name: "SmokeDegree",
                    component: () =>
                        import ("@/views/settings/smokeDegree/SmokeDegree.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "SmokeDegree", active: true }
                        ],
                        pageTitle: "SmokeDegree",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/Position",
                    name: "Position",
                    component: () =>
                        import ("@/views/settings/position/Position.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Position", active: true }
                        ],
                        pageTitle: "Position",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/KinshipType",
                    name: "KinshipType",
                    component: () =>
                        import ("@/views/settings/kinshipType/KinshipType.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "KinshipType", active: true }
                        ],
                        pageTitle: "KinshipType",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/ReportType",
                    name: "ReportType",
                    component: () =>
                        import ("@/views/settings/reportType/ReportType.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "ReportType", active: true }
                        ],
                        pageTitle: "ReportType",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/ScanType",
                    name: "ScanType",
                    component: () =>
                        import ("@/views/settings/scanType/ScanType.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "ScanType", active: true }
                        ],
                        pageTitle: "ScanType",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/AnalysisType",
                    name: "AnalysisType",
                    component: () =>
                        import ("@/views/settings/analysisType/AnalysisType.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "AnalysisType", active: true }
                        ],
                        pageTitle: "AnalysisType",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/ExtraService",
                    name: "ExtraService",
                    component: () =>
                        import ("@/views/settings/extraService/ExtraService.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "ExtraService", active: true }
                        ],
                        pageTitle: "ExtraService",
                        rule: "admin"
                    }
                },
                // {
                //     path: "/Setting/PaymentPartItem",
                //     name: "PaymentPartItem",
                //     component: () =>
                //         import ("@/views/settings/paymentPartItem/PaymentPartItem.vue"),
                //     meta: {
                //         breadcrumb: [
                //             { title: "Home", url: "/" },
                //             { title: "Setting" },
                //             { title: "PaymentPartItem", active: true }
                //         ],
                //         pageTitle: "PaymentPartItem",
                //         rule: "admin"
                //     }
                // },
                {
                    path: "/Setting/DocumentType",
                    name: "DocumentType",
                    component: () =>
                        import ("@/views/settings/documentType/DocumentType.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "DocumentType", active: true }
                        ],
                        pageTitle: "DocumentType",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/Department",
                    name: "Department",
                    component: () =>
                        import ("@/views/settings/department/Department.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Department", active: true }
                        ],
                        pageTitle: "Department",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/GeneralSetting",
                    name: "GeneralSetting",
                    component: () =>
                        import ("@/views/settings/generalSetting/GeneralSetting.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "General Setting", active: true }
                        ],
                        pageTitle: "General Setting",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/Status",
                    name: "Status",
                    component: () =>
                        import ("@/views/settings/status/Status.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Status", active: true }
                        ],
                        pageTitle: "Status",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/Specialty",
                    name: "Specialty",
                    component: () =>
                        import ("@/views/settings/specialty/Specialty.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Specialty", active: true }
                        ],
                        pageTitle: "Specialty",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/Services",
                    name: "Services",
                    component: () =>
                        import ("@/views/settings/service/Service.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Services", active: true }
                        ],
                        pageTitle: "Services",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/Sponsors",
                    name: "Sponsors",
                    component: () =>
                        import ("@/views/settings/sponsor/Sponsor.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Sponsors", active: true }
                        ],
                        pageTitle: "Sponsors",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/SurgeryCategories",
                    name: "SurgeryCategories",
                    component: () =>
                        import ("@/views/settings/surgeryCategory/SurgeryCategory.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "SurgeryCategories", active: true }
                        ],
                        pageTitle: "SurgeryCategories",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/Surgeries",
                    name: "Surgeries",
                    component: () =>
                        import ("@/views/settings/surgery/Surgery.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "Surgeries", active: true }
                        ],
                        pageTitle: "Surgeries",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/SurgeryAddEdit/:Id?",
                    name: "SurgeryAddEdit",
                    component: () =>
                        import ("@/views/settings/surgery/DataViewSidebar.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },

                            { title: "surgery Add&Edit", active: true }
                        ],
                        pageTitle: "surgery Add&Edit",
                        rule: "admin"
                    }
                },

                {
                    path: "/Setting/SurgeryItems",
                    name: "SurgeryItems",
                    component: () =>
                        import ("@/views/settings/surgeryItem/SurgeryItem.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "SurgeryItems" },
                            { title: "Surgeries", active: true }
                        ],
                        pageTitle: "SurgeryItems",
                        rule: "admin"
                    }
                },
                {
                    path: "/Setting/UserNotificationSetting",
                    name: "UserNotificationSetting",
                    component: () =>
                        import (
                            "@/views/settings/userNotificationSetting/UserNotificationSetting.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting" },
                            { title: "UserNotificationSetting", active: true }
                        ],
                        pageTitle: "UserNotificationSetting",
                        rule: "admin"
                    }
                },
                {
                    path: "/Doctor",
                    name: "Doctor",
                    component: () =>
                        import ("@/views/doctor/Doctor.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Doctor", active: true }
                        ],
                        pageTitle: "doctors",
                        rule: "adminaccountmanager"
                    }
                },
                {
                    path: "/Hospital",
                    name: "Hospital",
                    component: () =>
                        import ("@/views/Hospital/Hospitals.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Hospital", active: true }
                        ],
                        pageTitle: "Hospitals",
                        rule: "hospitals"
                    }
                },
                {
                    path: "/Hospital-add/Edit/:ID?",
                    name: "Hospital-add/Edit",
                    component: () =>
                        import ("@/views/Hospital/Hospital_Edit.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Hospital Add/Edit ", active: true }
                        ],
                        pageTitle: "Hospital Add/Edit ",
                        rule: "hospitals"
                    }
                },

                ,
                {
                    path: "/HospitalSurgeries-add/Edit/:ID?/:HospitalSurgeryID?",
                    name: "HospitalSurgeries-add/Edit",
                    component: () =>
                        import ("@/views/HospitalSurgeries/HospitalSurgeries_Edit.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Hospital Surgeries Add/Edit ", active: true }
                        ],
                        pageTitle: "Hospital Surgeries Add/Edit ",
                        rule: "hospitals"
                    }
                },

                {
                    path: "/doctor-add/Edit/:ID?",
                    name: "doctor-add/Edit",
                    component: () =>
                        import ("@/views/doctor/Doctor_Edit.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Doctor Add/Edit ", active: true }
                        ],

                        pageTitle: "Doctor Add/Edit ",
                        rule: "adminaccountmanager"
                    }
                },
                {
                    path: "/MedicalWebinar",
                    name: "MedicalWebinar",
                    component: () =>
                        import ("@/views/medicalWebinar/MedicalWebinar.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Medical Webinar", active: true }
                        ],
                        pageTitle: "Medical Webinar",
                        rule: "admin"
                    }
                },
                {
                    path: "/AgentPatientRate",
                    name: "Agent Patient Rate",
                    component: () =>
                        import ("@/views/AgentPatientRate/AgentPatientRate.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Agent Patient Rate", active: true }
                        ],
                        pageTitle: "Agent Patient Rate",
                        rule: "adminagentssupervisor"
                    }
                },
                {
                    path: "/ViewHistoryPatientAgent/:AgentPatientUserID?",
                    name: "ViewHistoryPatientAgent",
                    component: () =>
                        import ("@/views/AgentPatientRate/ViewHistoryPatientAgent.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "View History Patient Agent", active: true }
                        ],
                        pageTitle: "View History Patient Agent",
                        rule: "adminagentssupervisor"
                    }
                },

                {
                    path: "/AccountManagerRate",
                    name: "AccountManagerRate",
                    component: () =>
                        import ("@/views/AccountManagerRate/AccountMaganerRate.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Account Manager Rate", active: true }
                        ],
                        pageTitle: "Account Manager Rate",
                        rule: "adminaccountmanagersupervisor"
                    }
                },
                {
                    path: "/LiveSessions",
                    name: "LiveSessions",
                    component: () =>
                        import ("@/views/LiveSession/MeetingsList.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Live Session", active: true }
                        ],
                        pageTitle: "Live Session",
                        rule: "admin"
                    }
                },
                {
                    path: "/ViewAccountManagerRateHistory",
                    name: "ViewAccountManagerRateHistory",
                    component: () =>
                        import ("@/views/AccountManagerRate/ViewAccountManagerRateHistory.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "View Account Manager Rate History", active: true }
                        ],
                        pageTitle: "View Account Manager Rate History",
                        rule: "adminaccountmanagersupervisor"
                    }
                },

                {
                    path: "/Patient",
                    name: "Patient",
                    component: () =>
                        import ("@/views/Patient/Patient.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient", active: true }
                        ],
                        pageTitle: "Patient",
                        rule: "adminPatientAgent"
                    }
                },
                {
                    path: "/PatientEdit/:ID?",
                    name: "PatientEdit",
                    component: () =>
                        import ("@/views/Patient/PatientEdit.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "PatientEdit", active: true }
                        ],
                        pageTitle: "PatientEdit",
                        rule: "adminPatientAgent"
                    }
                },
                {
                    path: "/PendingMedicalDocumentation/:ID?",
                    name: "PendingMedicalDocumentation",
                    component: () =>
                        import ("@/views/Patient/PendingMedicalDocumentation.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Pending Medical Documentation", active: true }
                        ],
                        pageTitle: "Pending Medical Documentation",
                        rule: "adminPatientAgent"
                    }
                },
                {
                    path: "/PendingPayment/:ID?",
                    name: "PendingPayment",
                    component: () =>
                        import ("@/views/Patient/PendingPayment.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Pending Payment", active: true }
                        ],
                        pageTitle: "Pending Payment",
                        rule: "adminPatientAgent"
                    }
                },
                {
                    path: "/PatientReservationSurgery/:ID?",
                    name: "PatientReservationSurgery",
                    component: () =>
                        import ("@/views/Patient/PatientReservationSurgery.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Surgeries and Treatments", active: true }
                        ],
                        pageTitle: "Surgeries and Treatments",
                        rule: "adminPatientAgent"
                    }
                },


                {
                    path: "/PatientMedicalHistory/:ID?",
                    name: "PatientMedicalHistory",
                    component: () =>
                        import (
                            "@/views/Patient/PatientMedicalHistory/PatientMedicalHistory.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient Medical History", active: true }
                        ],
                        pageTitle: "Patient Medical History",
                        rule: "adminPatientAgent"
                    }
                },
                {
                    path: "/AvailabilitySchedule/:ID?",
                    name: "AvailabilitySchedule",
                    component: () =>
                        import ("@/views/AvailabilitySchedule/AvailabilitySchedule.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Availability Schedule", active: true }
                        ],
                        pageTitle: "Availability Schedule",
                        rule: "adminaccountmanager"
                    }
                },
                {
                    path: "/DoctorAddAvailability/:ID?",
                    name: "DoctorAddAvailability",
                    component: () =>
                        import ("@/views/AvailabilitySchedule/DoctorAddAvailability.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Add Availability", active: true }
                        ],
                        pageTitle: "Add Availability",
                        rule: "adminaccountmanager"
                    }
                },
                {
                    path: "/DoctorEditAvailability/:ID?",
                    name: "DoctorEditAvailability",
                    component: () =>
                        import ("@/views/AvailabilitySchedule/DoctorEditAvailability.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Edit Availability", active: true }
                        ],
                        pageTitle: "Edit Availability",
                        rule: "adminaccountmanager"
                    }
                },
                {
                    path: "/PatientMedicalHistoryLists/:ID?",
                    name: "PatientMedicalHistoryLists",
                    component: () =>
                        import (
                            "@/views/Patient/PatientMedicalHistoryLists/PatientMedicalHistoryList.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient Medical History Reports", active: true }
                        ],
                        pageTitle: "Patient Medical History Reports",
                        rule: "adminPatientAgent"
                    }
                },
                {
                    path: "/DoctorSession/:ID?",
                    name: "DoctorSession",
                    component: () =>
                        import ("@/views/reservation/doctorSession/DoctorSession.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "DoctorSession", active: true }
                        ],
                        pageTitle: "DoctorSession",
                        rule: "adminaccountmanager"
                    }
                },
                {
                    path: "/ReservationSurgery",
                    name: "ReservationSurgery",
                    component: () =>
                        import (
                            "@/views/reservation/ReservationSurgery/ReservationSurgery.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "ReservationSurgery", active: true }
                        ],
                        pageTitle: "ReservationSurgery",
                        rule: "adminaccountmanager"
                    }
                },
                {
                    path: "/ReservationSurgery-Details/:ID?",
                    name: "ReservationSurgery-Details",
                    component: () =>
                        import (
                            "@/views/reservation/ReservationSurgery/ReservationSurgeryTabMain.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "ReservationSurgery-Details", active: true }
                        ],
                        pageTitle: "ReservationSurgery-Details",
                        rule: "allagentandadmin"
                    }
                },

                {
                    path: "/GeneralReservation",
                    name: "GeneralReservation",
                    component: () =>
                        import (
                            "@/views/reservation/GeneralReservation.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Booking", active: true }
                        ],
                        pageTitle: "Booking",
                        rule: "booking"
                    }
                },
                {
                    path: "/ReservationSession/:ID?/:PatientID?/:IsAppointment?",
                    name: "ReservationSession",
                    component: () =>
                        import (
                            "@/views/reservation/ReservationSession/ReservationSession.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Reservation Session", active: true }
                        ],
                        pageTitle: "Reservation Session",
                        rule: "allagentandadmin"
                    }
                },
                {
                    path: "/ReservationSession-Add-Edit/:ID?/:PatientID?",
                    name: "ReservationSession-Add-Edit",
                    component: () =>
                        import (
                            "@/views/reservation/ReservationSession/ReservationSession-Add-Edit/Add-Edit.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Reservation Session-Add-Edit", active: true }
                        ],
                        pageTitle: "Reservation Session-Add-Edit",
                        rule: "allagentandadmin"
                    }
                },

                {
                    path: "/Reservation",
                    name: "Reservation",
                    component: () =>
                        import ("@/views/reservation/reservation/Reservation.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Reservation", active: true }
                        ],
                        pageTitle: "Reservation",
                        rule: "admin"
                    }
                },
                {
                    path: "/FillterReservationSession",
                    name: "Fillter Reservation Session",
                    component: () =>
                        import ("@/views/reservation/FillterReservationSession/FillterReservationSession.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Reservation with different language", active: true }
                        ],
                        pageTitle: "Reservation with different language",
                        rule: "admin"
                    }
                },
                {
                    path: "/Equation/PatientReservationEquation",
                    name: "PatientReservationEquation",
                    component: () =>
                        import ("@/views/reservation/EquationReservation.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Reservation Finanical Balance", active: true }
                        ],
                        rule: "admin"
                    }
                },
                {
                    path: "/ProfiteReport",
                    name: "ProfiteReport",
                    component: () =>
                        import ("@/views/FinanicalReports/ProfiteReport.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Profite Report", active: true }
                        ],
                        rule: "admin"
                    }
                },
                {
                    path: "/Invoices",
                    name: "Invoices",
                    component: () =>
                        import ("@/views/FinanicalReports/Invoices.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Invoices", active: true }
                        ],
                        rule: "admin"
                    }
                },
                {
                    path: "/settlementReport",
                    name: "settlementReport",
                    component: () =>
                        import ("@/views/FinanicalReports/settlementReport.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "settlement Report", active: true }
                        ],
                        rule: "admin"
                    }
                },




                {
                    path: "/Reservation-edit/:ID?",
                    name: "Reservation-edit",
                    component: () =>
                        import ("@/views/reservation/reservation/Reservation-edit.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Reservation-edit", active: true }
                        ],
                        pageTitle: "Reservation-edit",
                        rule: "admin"
                    }
                },
                {
                    path: "/User",
                    name: "User",
                    component: () =>
                        import ("@/views/user/User.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "User Management", active: true }
                        ],
                        pageTitle: "UserManagement",
                        rule: "editor"
                    }
                },
                {
                    path: "/User-Add-Edit/:ID?",
                    name: "User-Add-Edit",
                    component: () =>
                        import ("@/views/user/add-edit/Add-Edit.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Add-Edit", active: true }
                        ],
                        pageTitle: "Add-Edit",
                        rule: "editor"
                    }
                },
                {
                    path: "/AppointmentSearch/:ID?",
                    name: "AppointmentSearch",
                    component: () =>
                        import ("@/views/appointment/search/DoctorSearch.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Appointment Search", active: true }
                        ],
                        pageTitle: "Appointment Search",
                        rule: "adminPatientAgent"
                    }
                },
            ]
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: "",
            component: () =>
                import ("@/layouts/full-page/FullPage.vue"),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                // {
                //     path: '/callback',
                //     name: 'auth-callback',
                //     component: () => import('@/views/Callback.vue'),
                //     meta: {
                //         rule: 'editor'
                //     }
                // },
                {
                    path: "/pages/login",
                    name: "page-login",
                    component: () =>
                        import ("@/views/pages/login/Login.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Login"
                    }
                },
                {
                    path: "/pages/reset-password",
                    name: "page-reset-password",
                    component: () =>
                        import ("@/views/pages/ResetPassword.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Reset Password "
                    }
                },

                {
                    path: "/pages/register",
                    name: "page-register",
                    component: () =>
                        import ("@/views/pages/register/Register.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Register "
                    }
                },
                {
                    path: "/registration/registerType",
                    name: "registerType",
                    component: () =>
                        import ("@/views/pages/register/RegisterType.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Register "
                    }
                },

                {
                    path: "/pages/forgot-password",
                    name: "page-forgot-password",
                    component: () =>
                        import ("@/views/pages/ForgotPassword.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Forgot Password "
                    }
                },
                {
                    path: "/pages/reset-password",
                    name: "page-reset-password",
                    component: () =>
                        import ("@/views/pages/ResetPassword.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Reset Password "
                    }
                },
                {
                    path: "/pages/lock-screen",
                    name: "page-lock-screen",
                    component: () =>
                        import ("@/views/pages/LockScreen.vue"),
                    meta: {
                        rule: "editor",
                        pageTitle: "Lock Screen "
                    }
                },
                {
                    path: "/pages/comingsoon",
                    name: "page-coming-soon",
                    component: () =>
                        import ("@/views/pages/ComingSoon.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Coming Soon "
                    }
                },
                {
                    path: "/pages/error-404",
                    name: "page-error-404",
                    component: () =>
                        import ("@/views/pages/Error404.vue"),
                    meta: {
                        rule: "anonymous"
                    }
                },
                {
                    path: "/pages/error-500",
                    name: "page-error-500",
                    component: () =>
                        import ("@/views/pages/Error500.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/pages/not-authorized",
                    name: "page-not-authorized",
                    component: () =>
                        import ("@/views/pages/NotAuthorized.vue"),
                    meta: {
                        rule: "anonymous"
                    }
                },

                {
                    path: "/pages/maintenance",
                    name: "page-maintenance",
                    component: () =>
                        import ("@/views/pages/Maintenance.vue"),
                    meta: {
                        rule: "editor"
                    }
                }
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: "*",
            redirect: "/pages/error-404"
        }
    ]
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

router.beforeEach((to, from, next) => {
    // firebase.auth().onAuthStateChanged(() => {

    //     // get firebase current user
    //     const firebaseCurrentUser = firebase.auth().currentUser

    // if (
    //     to.path === "/pages/login" ||
    //     to.path === "/pages/forgot-password" ||
    //     to.path === "/pages/error-404" ||
    //     to.path === "/pages/error-500" ||
    //     to.path === "/pages/register" ||
    //     to.path === "/callback" ||
    //     to.path === "/pages/comingsoon" ||
    //     (auth.isAuthenticated() || firebaseCurrentUser)
    // ) {
    //     return next();
    // }

    // If auth required, check login. If login fails redirect to login page
    store.commit("SET_SetTitle", "");
    if (to.meta.authRequired) {
        if (!auth.isAuthenticated()) {
            router.push({ path: "/pages/login", query: { to: to.path } });
        }
    }

    return next();
    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });

    //});
});

export default router;