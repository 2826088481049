/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js"

export default {
    SET_BEARER(state, accessToken) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
        var now = new Date();
        var nowUtc = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

        const milliseconds = now - nowUtc;
        var hours = milliseconds / 36e5;

        hours = Math.floor(hours)
            //(new Date()).getHours()- (new Date()).getUTCHours()

        axios.defaults.headers.common['TimeZone'] = hours;
        axios.defaults.headers.common['Cache-Control'] = "no-cache";
    },
    REMOVE_BEARER() {
        delete axios.defaults.headers.common['Authorization']
    }
}