<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js";
import jwt from "@/http/requests/auth/jwt/index.js";
import router from "@/router";
import { Validator } from "vee-validate";

import ar from "vee-validate/dist/locale/ar";
import en from "vee-validate/dist/locale/en";
import fr from "vee-validate/dist/locale/fr";
import moment from 'moment';
// Install the Plugin.
import axios from "@/axios.js";
export default {
  data() {
    return {
      CurrentStatus: null,
    };
  },
  filter:{
      formatDate(value)
      {
             if (value) {
                return moment(String(value)).format('MM-YYYY-DD')
            }
      },
        formatDateTime(value)
      {
             if (value) {
                return moment(String(value)).format('HH:mm')
            }
      }
  },
  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr");
    },
    "$i18n.locale"(val) {
      switch (val) {
        case "ar":
          Validator.localize("ar", ar);
          break;

        case "en":
          Validator.localize("en", en);

          break;
        case "fr":
          Validator.localize("fr", fr);

          break;
        default:
          Validator.localize("en", en);
          break;
      }
    },
  },
  methods: {
    showInvalidImageSize(ImgSize) {
      this.$vs.notify({
        color: "danger",
        position: "center-bottom",
        title: this.$t("Error"),
        text: this.$t("showInvalidImageSize") + " " + ImgSize,
      });
    },
    StartLoading() {
      this.$vs.loading();
    },
    CloseLoading() {
      this.$vs.loading.close();
    },
    /* eslint-disable */
    checkAndAttachMapScriptGoogleMaps(callback) {
      if (!!window.google) {
        // If you're using vue cli, then directly checking
        // 'google' obj will throw an error at the time of transpiling.
        callback();
        return true;
      }
      window.mapApiInitialized = callback;
      let script = document.createElement("script");
      let language = this.$i18n.locale;
      let region = JSON.parse(
        localStorage.getItem("userInfo")
      ).countrycode.toUpperCase();
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAFu68ylJSETtU0lF9sWIj3bh524ntyImQ&hl=ar&libraries=places,geometry&language=${language}
      &region=${region}&callback=mapApiInitialized`;
      script.defer = true;
      script.async = true;
      document.body.appendChild(script);
    },

    checkAndAttachMapScript() {
      let initialized = !!window.google;
      return new Promise(async (resolve, reject) => {
        if (initialized) {
          return resolve(window.google);
        }

        initialized = true;
        window.mapApiInitialized = () => resolve(window.google);
        let language = await this.$i18n.locale;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAFu68ylJSETtU0lF9sWIj3bh524ntyImQ&libraries=places,geometry&language=${language}&callback=mapApiInitialized`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          resolve(window.google);
        };
        // script.onerror = () => reject(new Error("Script load error: " + src));
        document.body.appendChild(script);
      });
    },
    /* eslint-enable */

    showAddSuccess() {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("CreatedSuccessfully"),
      });
    },
    showUpdateSuccess() {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("UpdatedSuccessfully"),
      });
    },

    sessionBlock() {
      this.$vs.notify({
        color: "danger",
        position: "bottom-center",
        title: this.$t("SessionBlocked"),
        text: this.$t("SessionBlockMessage"),
      });
    },
    sessionOldSelected() {},
    showAddFailed(err) {
      if (err != "") {
        this.$vs.notify({
          color: "error",
          position: "bottom-center",
          title: this.$t("Error"),
          text: err,
        });
      } else {
        this.$vs.notify({
          color: "error",
          position: "bottom-center",
          title: this.$t("Error"),
          text: this.$t("CreatedFailed"),
        });
      }
    },
    showUpdateFailed(err) {
      if (err != "") {
        console.log(err);
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: err,
        });
      } else {
        console.log(err);
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: this.$t("UpdatedFailed"),
        });
      }
    },
    showDeleteSuccess() {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("DeletedSuccessfully"),
      });
    },
    showDeleteFailed(err) {
      if (err != "") {
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: err,
        });
      } else {
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: this.$t("DeleteFailed"),
        });
      }
    },
    showFailed() {
      this.$vs.notify({
        color: "danger",
        position: "bottom-center",
        title: this.$t("Error"),
        text: this.$t("ErrorTryLater"),
      });
    },

    showFillData() {
      this.$vs.notify({
        color: "danger",
        position: "right-bottom",
        text: "Please fill all required data...",
      });
    },
    showError() {
      this.$vs.notify({
        color: "danger",
        position: "right-bottom",
        title: this.$t("Error"),
        text: this.$t("showError"),
      });
    },
    showSuccessCreate() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("Create"),
        text: this.$t("RegisterCreatedSuccessfuly"),
      });
    },
    showAlreadyExist() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("AlreadyExist"),
        text: this.$t("ThisItemAlreadyExist"),
      });
    },
    showAddDriverToBiadjoSuccess() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("AssignToDriver"),
        text: this.$t("Assign"),
      });
    },
    showSuccess() {
      this.$vs.notify({
        color: "success",
        position: "top-center",
        title: this.$t("Update"),
        text: this.$t("showUpdate"),
      });
    },
    showSuccessAddNote() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("Update"),
        text: this.$t("SuccessAddNote"),
      });
    },
    // showDriverAddeddSuccessfuly() {
    //   this.$vs.notify({
    //     color: "primary",
    //     position: "top-center",
    //     title: this.$t("Success"),
    //     text: this.$t("DriverAddeddSuccessfuly")
    //   });
    // },
    showNoRecords() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("Search"),
        text: this.$t("showNoDataAvailable"),
      });
    },
    showCheckEmail() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("ResetPassword"),
        text: this.$t("showCheckEmail"),
      });
    },
    showPasswordChanged() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("ResetPassword"),
        text: this.$t("showPasswordChanged"),
      });
    },
    notAllowedToAccessCurrentPage(CurrentStatus) {
      if (
        CurrentStatus == "BS11" ||
        CurrentStatus == "BS13" ||
        CurrentStatus == "BS14" ||
        CurrentStatus == "BS15" ||
        CurrentStatus == "BS16" ||
        CurrentStatus == "SO3" ||
        CurrentStatus == "SO4" ||
        CurrentStatus == "CS2" ||
        CurrentStatus == "CS3" ||
        CurrentStatus == "PS1" ||
        CurrentStatus == "DPS1"
      ) {
        this.$router.go(-1);
      }
    },
    InvalidImageType() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("InvalidType"),
        text: this.$t("InvalidImageType"),
      });
    },
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark")) {
          document.body.classList.remove("theme-semi-dark");
        }
        document.body.classList.add("theme-dark");
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark")) {
          document.body.classList.remove("theme-dark");
        }
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark")) {
          document.body.classList.remove("theme-dark");
        }
        if (document.body.className.match("theme-semi-dark")) {
          document.body.classList.remove("theme-semi-dark");
        }
      }
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
    },
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
  },
  async created() {
    //this.$store.commit("UpdateSessionExpired", false);
    var timedifference = new Date().getHours() - new Date().getUTCHours();
    axios.defaults.headers.common["TimeZone"] = timedifference;
    // jwt
    jwt.init(this);

    var vm = this;
    /* navigator.serviceWorker.onmessage = (event) => {
      console.log('Message ');
     
        vm.$store.dispatch("fetchNotifications");
    }*/

    window.addEventListener("focus", function () {
      vm.$store.dispatch("fetchNotifications");
    });

    if (this.$store.state.auth.isUserLoggedIn()) {
      // this.$store
      //   .dispatch("auth/refreshCurrentUser")
      //   .then(response => {
      //     if (response.data.status == "AS1") {
      //       this.$acl.change(response.data.accounttype);
      //     }
      //     if (response.data.status != "AS1") {
      //       this.$store.commit("CLEAR_USER_INFO", null, { root: true });
      //       this.$store.commit("REMOVE_USER_STORE", null, { root: true });
      //       this.$store.dispatch("auth/logout");
      //       router.push("/pages/login").catch(() => {});
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err);
      //     this.$store.commit("CLEAR_USER_INFO", null, { root: true });
      //     this.$store.commit("REMOVE_USER_STORE", null, { root: true });
      //     this.$store.dispatch("auth/logout");
      //   });
    } else {
      router.push("/pages/login").catch(() => {});
    }

    if (localStorage.getItem("SaveLang") === "ar") {
      this.$vs.rtl = true;
    }
    window.showInvalidImageSize = this.showInvalidImageSize;
    window.showAddSuccess = this.showAddSuccess;
    window.showUpdateSuccess = this.showUpdateSuccess;
    window.showAddFailed = this.showAddFailed;
    window.showUpdateFailed = this.showUpdateFailed;
    window.showDeleteSuccess = this.showDeleteSuccess;
    window.showDeleteFailed = this.showDeleteFailed;
    window.showFailed = this.showFailed;
    window.StartLoading = this.StartLoading;
    window.CloseLoading = this.CloseLoading;
    window.showError = this.showError;
    window.InvalidImageType = this.InvalidImageType;
    window.showFillData = this.showFillData;
    window.showSuccess = this.showSuccess;
    window.showAddDriverToBiadjoSuccess = this.showAddDriverToBiadjoSuccess;
    window.showNoRecords = this.showNoRecords;
    window.showCheckEmail = this.showCheckEmail;
    window.showPasswordChanged = this.showPasswordChanged;
    window.notAllowedToAccessCurrentPage = this.notAllowedToAccessCurrentPage;
    window.showSuccessCreate = this.showSuccessCreate;
    window.showAlreadyExist = this.showAlreadyExist;
    window.checkAndAttachMapScript = this.checkAndAttachMapScript;
    window.showDriverAddeddSuccessfuly = this.showDriverAddeddSuccessfuly;
    window.showSuccessAddNote = this.showSuccessAddNote;
    window.checkAndAttachMapScriptGoogleMaps =
      this.checkAndAttachMapScriptGoogleMaps;

    let dir = this.$vs.rtl ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", dir);

    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("scroll", this.handleScroll);
    this.$emit("updateNavbarColor", "darkturquoise");
    // // this.$vs.theme({ primary: '#f1c717' });
    // this.$vs.theme({ primary: "#3268a8" });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
/* .vs-navbar {
    background-image: url("assets/images/pages/navbarheader.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

#loading-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  position: absolute;
}

.loading-logo {
  position: absolute;
  left: calc(50% - 45px);
  top: 35%;
}

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}

.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(121, 97, 249, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}

.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}

.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(121, 97, 249, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
