import axios from "axios";


//const domain = "http://localhost:58870/"
   //const domain = process.env.VUE_APP_Base_Url
 const domain ="https://api.daaweny.com";
//const domain ="http://38.242.227.244:8082/";

export default axios.create({
  baseURL: domain
  // You can add your headers here
});

